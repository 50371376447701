import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  showErrorNotification,
  showSuccessNotification,
} from "../../notification/notificationSlice";

import {
  addPaymentMethod,
  changeDefaultPaymentMethod,
  deletePaymentMethod,
  getPaymentMethods,
} from "../../api/paymentApi/paymentApi";
import { FieldValues } from "react-hook-form";
import { setPaymentMethods } from "../../payment/paymentSlice";

export const getPaymentMethodsThunk = createAsyncThunk(
  "thunkSlice/getPaymentMethodsThunk",
  async (_, { dispatch }) => {
    try {
      const paymentMethods = await dispatch(
        getPaymentMethods.initiate({}),
      ).unwrap();
      dispatch(setPaymentMethods(paymentMethods));
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
  },
);

export const addPaymentMethodThunk = createAsyncThunk(
  "thunkSlice/getPaymentMethodsThunk",
  async (data: FieldValues, { dispatch }) => {
    try {
      await dispatch(addPaymentMethod.initiate(data)).unwrap();

      await dispatch(getPaymentMethodsThunk());
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
  },
);

export const changeDefaultPaymentMethodThunk = createAsyncThunk(
  "thunkSlice/changeDefaultPaymentMethodThunk",
  async (id: number, { dispatch }) => {
    try {
      await dispatch(changeDefaultPaymentMethod.initiate({ id })).unwrap();
      dispatch(showSuccessNotification({ message: "Default payment method changed" }));
      await dispatch(getPaymentMethodsThunk());
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
  },
);

export const deletePaymentMethodThunk = createAsyncThunk(
  "thunkSlice/deletePaymentMethodThunk",
  async (id: number, { dispatch }) => {
    try {
      await dispatch(deletePaymentMethod.initiate({ id })).unwrap();
      dispatch(showSuccessNotification({ message: "Payment method deleted" }));
      await dispatch(getPaymentMethodsThunk());
    } catch (error: any) {
      dispatch(
        showErrorNotification({
          message: error?.data?.message ?? error?.data?.error,
        }),
      );
      console.error(error);
    }
  },
);
