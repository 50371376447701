import { createApi } from "@reduxjs/toolkit/query/react";
import { baseCustomFetchBase } from "../service/customFetchBase";

import { FieldValues } from "react-hook-form";

const paymentMethod = "payment-method";
const paymentMethods = "payment-methods";

const paymentApi = createApi({
  reducerPath: "form/paymentApi",
  baseQuery: baseCustomFetchBase,
  endpoints: (builder) => ({
    getPaymentMethods: builder.mutation<any, any>({
      query: () => ({
        url: `${paymentMethods}`,
        method: "GET",
      }),
    }),
    addPaymentMethod: builder.mutation<any, FieldValues>({
      query: (data) => ({
        url: `${paymentMethod}`,
        method: "POST",
        body: data,
      }),
    }),
    changeDefaultPaymentMethod: builder.mutation<any, { id: number }>({
      query: ({ id }) => ({
        url: `${paymentMethod}/${id}`,
        method: "PUT",
        body: { isDefault: true },
      }),
    }),
    deletePaymentMethod: builder.mutation<any, { id: number }>({
      query: ({ id }) => ({
        url: `${paymentMethod}/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export default paymentApi;

export const {
  getPaymentMethods,
  addPaymentMethod,
  changeDefaultPaymentMethod,
  deletePaymentMethod,
} = paymentApi.endpoints;
